.large-icon {
    font-size: 24px; /* Adjust this value to make the icons larger or smaller */
    margin-right: 10px;
}

.nav-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nav-label span {
    font-weight: bold;
}