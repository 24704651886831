.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
  margin-left: 240px; /* Adjust this value to match your sidebar width */
  padding-top: 60px; /* Adjust this value to match your header height */
  overflow-y: auto;
}

@media (max-width: 991.98px) {
  .content-wrap {
    margin-left: 0;
  }
}

.card-overview {
  background-color: #f8f9fa;
  border-left: 4px solid #007bff;
}

.card-data-source {
  transition: transform 0.2s ease-in-out;
}

.card-data-source:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.data-source-icon {
  font-size: 2rem;
}

.card-request {
  background-color: #f8f9fa;
  border: 1px dashed #007bff;
}